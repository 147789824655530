/* eslint-disable indent */
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { DropdownSelectField } from '@jutro/components';
import { useTranslator, IntlContext } from '@jutro/locale';
import { Flex, FlexItem } from "@jutro/layout";
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import classNames from 'classnames';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getMessageContent } from './index';
import { getVehicleName, getCurrentVehicleIndexByNumber, getElementByXpath } from '../WMICRemoveVehicleWizardUtils';
import WMICRemoveVehicleMessage from '../../WMICRemoveVehicleMessage/WMICRemoveVehicleMessage';
import { STATE_KEYS, PRIMARY_USE_TEXT, MESSAGE_TYPES, VEHICLE_KEYS, VIEW_KEYS } from '../WMICRemoveVehicleWizardConsts';

// Verifies that all drivers have been assigned to a vehicle
export const checkPrincipalDriversAssignment = (modelState) => {
    const assignedDrivers = _.uniq(_.map(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => v.principalDriver));

    return ({
        isPrimaryDriversAssignmentCorrect:
            modelState[STATE_KEYS.REMAINING_VEHICLES].length < modelState[STATE_KEYS.DRIVERS_TO_ASSIGN].length
            ? assignedDrivers.length === modelState[STATE_KEYS.REMAINING_VEHICLES].length
            : assignedDrivers.length === modelState[STATE_KEYS.DRIVERS_TO_ASSIGN].length
    });
};

export const checkPrincipalDriversIsNotEmpty = (modelState) => {
    return _.some(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => !(v.principalDriver));
};

export const setRemainingVehiclesDriverAssignmentError = (modelState, viewStateDispatch, hasClickedContinue) => {
    modelState[STATE_KEYS.REMAINING_VEHICLES].forEach((od, idx, array) => { 
        array[idx][VEHICLE_KEYS.ASSIGN_DRIVER_ERROR] = hasClickedContinue === true && (array[idx][VEHICLE_KEYS.PRINCIPAL_DRIVER] === null || array[idx][VEHICLE_KEYS.PRINCIPAL_DRIVER] === undefined);
    });
    viewStateDispatch({type:VIEW_KEYS.HAS_CLICKED_CONTINUE, value: hasClickedContinue});
};

const WMICRemoveVehicleAssignPrimaryDriversStep = (props) => {
    const { modelState, modelStateDispatch, viewState, viewStateDispatch, ...otherProps } = props;
    const translator = useTranslator();
    const checkResult = checkPrincipalDriversAssignment(modelState);
    const intl = useContext(IntlContext);
    
    const message =
        viewState[VIEW_KEYS.HAS_CLICKED_CONTINUE] && !checkPrincipalDriversIsNotEmpty(modelState) && !checkResult.isPrimaryDriversAssignmentCorrect
            ? getMessageContent(MESSAGE_TYPES.PRIMARY_DRIVERS_ASSIGNMENT, translator, intl, modelState, null, null, checkResult)
            : null;

    const formattedNumber = (amount) => intl.formatNumber(amount);
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus

    const shouldShowValidationMsg = (vehicle) => vehicle[VEHICLE_KEYS.ASSIGN_DRIVER_ERROR];

    const focusOnFirstValidationMsg = () => {  
        const validationError = _.some(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => shouldShowValidationMsg(v));
        if (validationError) {
            const firstIndexWithError = modelState[STATE_KEYS.REMAINING_VEHICLES].findIndex(v=>shouldShowValidationMsg(v))
            const driverDropdown = getElementByXpath("//input[contains(@id,'vehicle" + firstIndexWithError + "_primary_drivers_dropdown')]");
            driverDropdown.setAttribute('tabindex', '0');
            driverDropdown.focus({preventScroll: true});
        }
    };
    
    const focusRemoveVehicleAssignPrimaryLabel = () => {
        const headerTag = document.getElementById('WMICRemoveVehicleAssignPrimaryLabel');
        if (headerTag) {
            headerTag.setAttribute('tabindex', '0');
            headerTag.focus({preventScroll: true});
        }
    };

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
            viewStateDispatch({type:VIEW_KEYS.HAS_CLICKED_CONTINUE, value: false});
            focusRemoveVehicleAssignPrimaryLabel();
        }
        focusOnFirstValidationMsg();
    });

    const getVehicleNameWithWarning = (vehicle) => {
        return translator(messages.assignDriversValidationAriaWarningMessage,{vehicle:getVehicleName(vehicle)});
    }

    return (
        <div {...otherProps}>
            {message && <WMICRemoveVehicleMessage {...message} />}
            <p className={classNames('mb-6', styles['wmic-edit-title'])}>
                <div className={styles['assign-drivers-title']} id="WMICRemoveVehicleAssignPrimaryLabel">
                    {WMICRichTextUtil.translateRichText(translator(messages.assignPrimaryDriversMessage))}
                    <span className={styles.wmicTooltipContainer}>
                        <WMICClickableIconTooltipComponent
                            tooltipMessage={translator(messages.assignPrimaryDriversToTheVehicleToolTip)}
                            placement="top"
                        />
                    </span>
                </div>
                <div className={styles['assign-drivers-title']}>
                    {WMICRichTextUtil.translateRichText(translator(messages.assignDriversCallMessage))}
                </div>
            </p>

            <Flex gap='none' className={styles.wmicFlexContainer} role='list'>
                {
                    _.map(modelState[STATE_KEYS.REMAINING_VEHICLES], (vehicle, idx) => (
                        <div className={styles.wmicFlexColumn} role='listitem'>
                            <FlexItem grow={1} className={styles.wmicBox}>
                                <div className={styles.wmicBoxContent} key={idx}>
                                    <h3 data-di-unmask-field htmlFor={`driver${idx}`}>
                                        <span className={styles.wmicStrong}>{getVehicleName(vehicle)}</span>
                                    </h3>
                                    <DropdownSelectField
                                        id={`vehicle${idx}_primary_drivers_dropdown`}
                                        className={classNames('field py-4')}
                                        label={shouldShowValidationMsg(vehicle)?getVehicleNameWithWarning(vehicle):getVehicleName(vehicle)}
                                        labelClassName={styles['wmicHidden']}
                                        availableValues={modelState[STATE_KEYS.DRIVERS_TO_ASSIGN]}
                                        value={vehicle.principalDriver}
                                        placeholder={translator(messages.assignDriversPleaseSelect)}
                                        onValueChange={(v) => {
                                            const vehicleIdx = getCurrentVehicleIndexByNumber(vehicle.vehicleNumber, modelState[STATE_KEYS.REMAINING_VEHICLES]);

                                            modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.${vehicleIdx}.principalDriver`, value: v });
                                            setRemainingVehiclesDriverAssignmentError(modelState, viewStateDispatch, false);
                                        }}
                                    />
                                    <div className={shouldShowValidationMsg(vehicle) ? cx(styles.wmicInputBoxNotes, styles.errorRed) : styles['wmicHidden']} >
                                        {translator(messages.assignDriversValidationMessage)} 
                                    </div>

                                    <div className="pb-4">
                                        <p>
                                            <strong>{translator(messages.use)}</strong> {PRIMARY_USE_TEXT[vehicle.primaryUse]}
                                        </p>
                                        <p>
                                            <strong>{translator(messages.annualMileage)}</strong> {formattedNumber(vehicle.annualMileage)}
                                        </p>
                                    </div>
                                </div>
                            </FlexItem>
                        </div>
                        )
                    )
                }
            </Flex>
        </div>
    );
}

WMICRemoveVehicleAssignPrimaryDriversStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired
};

export default WMICRemoveVehicleAssignPrimaryDriversStep;
