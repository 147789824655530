/* eslint-disable indent */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { DropdownSelectField } from '@jutro/components';
import { Flex, FlexItem } from "@jutro/layout";
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import classNames from 'classnames';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName, getOccasionalDrivers, setOccasionalDriversAssignVehicleError, getElementByXpath } from '../WMICRemoveVehicleWizardUtils';
import { STATE_KEYS, OCCASIONAL_DRIVER_KEYS } from '../WMICRemoveVehicleWizardConsts';

export const checkOccasionalPrimaryDrivers = (modelState) => {
    return modelState[STATE_KEYS.OCCASIONAL_DRIVERS].length === 0 || _.some(modelState[STATE_KEYS.OCCASIONAL_DRIVERS], (d) => d.vehicleId === '' || !d.vehicleId);
};

const WMICRemoveVehicleAssignOccasionalDriversStep = (props) => {
    const { modelState, modelStateDispatch, ...otherProps } = props;
    const translator = useContext(TranslatorContext);
    const vehicles = _.map(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => ({ code: v.fixedId, name: getVehicleName(v) }) );
    const occasionalDrivers = getOccasionalDrivers(modelState);
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus
    const shouldShowValidationMsg = (driver) => driver[OCCASIONAL_DRIVER_KEYS.ASSIGN_VEHICLE_ERROR] === true;

    const focusOnFirstValidationMsg = () => {  
        const validationError = _.some(occasionalDrivers, (d) => shouldShowValidationMsg(d));
        if (validationError) {
            const firstIndexWithError = occasionalDrivers.findIndex(v=>shouldShowValidationMsg(v))
            const vehicleDropdown = getElementByXpath("//input[contains(@id,'occasional_driver" + firstIndexWithError + "_vehicles_dropdown')]");
            vehicleDropdown.setAttribute('tabindex', '0');
            vehicleDropdown.focus({preventScroll: true});
        }
    };

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
            focusRemoveVehicleAssignOccasionalLabel();
        }
        focusOnFirstValidationMsg();
    }); 

    const getDriverNameWithWarning = (driver) => {
        return translator(messages.assignVehicleOccasionalDriverAriaWarningMessage,{drivername:driver.displayName});
    }
    
    const focusRemoveVehicleAssignOccasionalLabel = () => {
        const headerTag = document.getElementById('WMICRemoveVehicleAssignOccasionalLabel');
        if (headerTag) {
            headerTag.setAttribute('tabindex', '0');
            headerTag.focus({preventScroll: true});
        }
    };

    return (
        <div {...otherProps}>
            <p className={classNames('mb-6', styles['wmic-edit-title'])}>
                <div className={styles['assign-drivers-title']} id="WMICRemoveVehicleAssignOccasionalLabel">
                    {WMICRichTextUtil.translateRichText(translator(messages.assignOccasionalDriversMessage))}
                    <span className={styles.wmicTooltipContainer}>
                        <WMICClickableIconTooltipComponent
                            tooltipMessage={translator(messages.assignOccasionalDriversToTheVehicleToolTip)}
                            placement="top"
                        />
                    </span>
                </div>
                <div className={styles['assign-drivers-title']}>
                    {WMICRichTextUtil.translateRichText(translator(messages.assignDriversCallMessage))}
                </div>
            </p>

            <Flex gap='none' className={styles.wmicFlexContainer} role='list'>
                {
                    _.map(occasionalDrivers, (driver, idx) => (
                        <div className={styles.wmicFlexColumn} role='listitem'>
                            <FlexItem grow={1} className={styles.wmicBox}>
                                <div className={styles.wmicBoxContent} key={idx}>
                                    <h3 data-di-mask htmlFor={`vehicles${idx}`}>
                                        <span className={styles.wmicStrong}>{driver.displayName}</span>
                                    </h3>
                                    <DropdownSelectField
                                        id={`occasional_driver${idx}_vehicles_dropdown`}
                                        className={classNames('field py-4')}
                                        availableValues={vehicles}
                                        value={driver.vehicleId}
                                        label={shouldShowValidationMsg(driver)?getDriverNameWithWarning(driver):driver.displayName}
                                        labelClassName={styles['wmicHidden']}
                                        placeholder={translator(messages.assignDriversPleaseSelect)}
                                        onValueChange={(v) => {
                                            modelStateDispatch({ type: `${STATE_KEYS.OCCASIONAL_DRIVERS}.${idx}`, value: {...driver, vehicleId: v} });
                                            setOccasionalDriversAssignVehicleError(false, modelState);
                                        }}
                                    />
                                    <div className={shouldShowValidationMsg(driver) ? classNames(styles.wmicInputBoxNotes, styles.errorRed) : styles['wmicHidden']} >
                                        {translator(messages.assignVehicleOccasionalDriverValidationMessage)} 
                                    </div>
                                </div>
                            </FlexItem>
                        </div>
                        )
                    )
                }
            </Flex>
        </div>
    );
}

WMICRemoveVehicleAssignOccasionalDriversStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired
};

export default WMICRemoveVehicleAssignOccasionalDriversStep;
