/**
 * 
 * @param {*} vehicle 
 * @returns 
 */
import _ from 'lodash';
import { STATE_KEYS, VIEW_KEYS, ANNUAL_MILEAGE_UNDER12000_TOGGLE, DRIVER_KEYS, VEHICLE_KEYS, OCCASIONAL_DRIVER_KEYS, VEHICLE_TYPES_ALLOWS_TO_REASSIGN, COMMON_ANNUAL_MILEAGE } from './WMICRemoveVehicleWizardConsts';

const getElementByXpath = (path) => {
    return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
  }

const changeCurrentState = (state, action) => {
    if (_.isObject(action.value) && !action.type) {
        return ({ ...state, ...action.value });
    }

    return _.set({ ...state }, action.type, action.value);
};

const mapStateData = (vehicleNumber, vehicles, drivers, vehicleDrivers, changeModel, modelStateDispatch, viewStateDispatch, editDeleteVehicleState) => {
    let vehicleToRemove = {...changeModel?.vehicle};

    const remainingVehicles = [];
    const originalVehicles = !_.isNull(changeModel) ? changeModel.originalVehicles?.map((v) => ({...v})) : [];

    _.forEach(vehicles, (v) => {
        const matchRemainingVehicle = !_.isNull(changeModel) ? changeModel.remainingVehicles?.find((d) => d.fixedId === v.fixedId.toString()) : null;

        const bucket = {
            [VEHICLE_KEYS.FIXED_ID]: v.fixedId.toString(),
            [VEHICLE_KEYS.PUBLIC_ID]: v.publicID,
            [VEHICLE_KEYS.DISPLAY_NAME]: v.displayName,
            [VEHICLE_KEYS.ANNUAL_MILEAGE]: v.annualMileage,
            [VEHICLE_KEYS.ANNUAL_MILEAGE_UNDER12000_TOGGLE]: matchRemainingVehicle?.annualMileageUnder12000Toggle ?? (v.annualMileage >= COMMON_ANNUAL_MILEAGE ? ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_DISPLAY_NON : ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_UNKNOWN),
            [VEHICLE_KEYS.MAKE]: v.make,
            [VEHICLE_KEYS.MODEL]: v.model,
            [VEHICLE_KEYS.PRIMARY_USE]: v.primaryUse,
            [VEHICLE_KEYS.VEHICLE_NUMBER]: v.vehicleNumber.toString(),
            [VEHICLE_KEYS.VEHICLE_TYPE]: v.vehicleType_WMIC,
            [VEHICLE_KEYS.YEAR]: v.year,
            [VEHICLE_KEYS.SHELL]: v.shell_WMIC,
            [VEHICLE_KEYS.CAMPER_ATTACHED]: v.camperAttached_WMIC,
            [VEHICLE_KEYS.PRINCIPAL_DRIVER]: matchRemainingVehicle?.primaryDriver,
            [VEHICLE_KEYS.VIN]: v.vin,
            [VEHICLE_KEYS.OCCASIONAL_DRIVERS]: []
        };
        // populate original vehicles
        if (_.isNull(changeModel)) {
            const originalPrimaryDriver = vehicleDrivers?.find((d) => d.vehicleID.toString() === v.fixedId.toString() && d.driverType === 'principal')?.driverID?.toString();
            originalVehicles.push({...bucket, principalDriver: originalPrimaryDriver});
        }

        if (v.vehicleNumber.toString() === vehicleNumber) {
            vehicleToRemove = bucket;
        } else if (VEHICLE_TYPES_ALLOWS_TO_REASSIGN.includes(bucket[VEHICLE_KEYS.VEHICLE_TYPE])) {
            remainingVehicles.push(bucket);
        }
    });

    const driversToAssign = _.map(drivers, (d) =>
        ({
            [DRIVER_KEYS.CODE]: d.fixedId.toString(),
            [DRIVER_KEYS.NAME]: d.displayName,
            [DRIVER_KEYS.FIRST_NAME]: d.person?.firstName,
            [DRIVER_KEYS.LAST_NAME]: d.person?.lastName
        })
    );

    modelStateDispatch({
        value: {
            [STATE_KEYS.JOB_ID]: changeModel?.jobId,
            [STATE_KEYS.REASON]: changeModel?.reason,
            [STATE_KEYS.EFFECTIVE_DATE]: changeModel?.date || getTodayDate(),
            [STATE_KEYS.VEHICLE_TO_REMOVE]: vehicleToRemove,
            [STATE_KEYS.REMAINING_VEHICLES]: remainingVehicles,
            [STATE_KEYS.DRIVERS_TO_ASSIGN]: driversToAssign,
            [STATE_KEYS.ORIGINAL_VEHICLES]: originalVehicles,
            [STATE_KEYS.STATUS]: changeModel?.status,
            [STATE_KEYS.OCCASIONAL_DRIVERS]: changeModel?.occasionalDrivers,
        }
    });

    if (editDeleteVehicleState) {
        viewStateDispatch({ value: {
            [VIEW_KEYS.CURRENT_VEHICLE_NUMBER]: editDeleteVehicleState.vehicleNumber,
            [VIEW_KEYS.STEP]: editDeleteVehicleState.step
        }});
    } else if (remainingVehicles.length > 0) {
        viewStateDispatch({
            type: VIEW_KEYS.CURRENT_VEHICLE_NUMBER,
            value: remainingVehicles[0]?.vehicleNumber
        });
    }
};

const getTodayDate = () => {
    const dt = new Date();
    return { year: dt.getFullYear(), month: (dt.getMonth()), day: dt.getDate()}
};

const getTomorrowDate = () => {
    const dt = new Date();
    dt.setDate(dt.getDate() + 1);
    return { year: dt.getFullYear(), month: (dt.getMonth()), day: dt.getDate()}
};

const getDateAfterDays = (d, afterDays) => {
    const dt = new Date(d.year, d.month, d.day);
    dt.setDate(dt.getDate() + afterDays);
    return { year: dt.getFullYear(), month: (dt.getMonth()), day: dt.getDate()};
}

const getVehicleName = (vehicle) => {
    return vehicle ? `${vehicle.make} ${vehicle.model} ${vehicle.year} (${vehicle.vehicleNumber})` : '';
};

const getCurrentVehicleIndexByNumber =(vehicleNumber, remainingVehicles) => {
    return remainingVehicles.findIndex((v) => v.vehicleNumber === vehicleNumber);
};

const isLastRemainingVehicle = (vehicleNumber, remainingVehicles) => {
    return  getCurrentVehicleIndexByNumber(vehicleNumber, remainingVehicles) === remainingVehicles.length - 1;
};

const isFirstRemainingVehicle = (vehicleNumber, remainingVehicles) => {
    return  getCurrentVehicleIndexByNumber(vehicleNumber, remainingVehicles) === 0;
};

const getOccasionalDrivers = (modelState, chanceGivenAssignVehicle) => {
    const occasionalDrivers = [];

    modelState.driversToAssign.forEach((d) => {
        const isAssignAsPrincipal = modelState.remainingVehicles.find((v) => v.principalDriver === d.code);

        if (!isAssignAsPrincipal) {
            const fileteredDrivers = modelState.occasionalDrivers?.filter((od) => od !== undefined);
            const bucket = fileteredDrivers?.find((o) => d.code === o.fixedId);

            if (bucket) {
                if (bucket.assignVehicleError === undefined && chanceGivenAssignVehicle !== undefined) {
                    bucket.assignVehicleError = chanceGivenAssignVehicle;
                }

                occasionalDrivers.push({...bucket});
            } else {
                occasionalDrivers.push({
                    [OCCASIONAL_DRIVER_KEYS.FIXED_ID]: d.code,
                    [OCCASIONAL_DRIVER_KEYS.DISPLAY_NAME]: d.name,
                    [OCCASIONAL_DRIVER_KEYS.FIRST_NAME]: d.firstName,
                    [OCCASIONAL_DRIVER_KEYS.LAST_NAME]: d.lastName,
                    [OCCASIONAL_DRIVER_KEYS.VEHICLE_ID]: null,
                    [OCCASIONAL_DRIVER_KEYS.ASSIGN_VEHICLE_ERROR]: undefined
                })
            }
        }
    });

    return occasionalDrivers;
};

const setInitialStepReasonSelectionError = (hasClickedContinue, modelState) => {
    // eslint-disable-next-line no-param-reassign
    modelState[STATE_KEYS.REASON_SELECTION_ERROR] = hasClickedContinue && (modelState[STATE_KEYS.REASON] === null || modelState[STATE_KEYS.REASON] === undefined);

    return modelState;
};

const setOccasionalDriversAssignVehicleError = (shouldValidate, modelState) => {
    // eslint-disable-next-line security/detect-object-injection, no-param-reassign
    modelState[STATE_KEYS.OCCASIONAL_DRIVERS].forEach((od, idx, array) => { array[idx][OCCASIONAL_DRIVER_KEYS.ASSIGN_VEHICLE_ERROR] = shouldValidate === true
        // eslint-disable-next-line security/detect-object-injection
        && (array[idx][OCCASIONAL_DRIVER_KEYS.VEHICLE_ID] === null || array[idx][OCCASIONAL_DRIVER_KEYS.VEHICLE_ID] === undefined);
    });
};

export {
    getElementByXpath,
    getTodayDate,
    getTomorrowDate,
    getDateAfterDays,
    changeCurrentState,
    mapStateData,
    getVehicleName,
    getCurrentVehicleIndexByNumber,
    isLastRemainingVehicle,
    isFirstRemainingVehicle,
    getOccasionalDrivers,
    setOccasionalDriversAssignVehicleError,
    setInitialStepReasonSelectionError
};